import { lazy } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import PageLoader from "../components/PageLoader/PageLoader";
import Login from "../pages/Login/Login";

const ReportsPage = lazy(() => import("../pages/Reports/Reports"));
const ContractNew = lazy(() => import("../pages/ContractNew/ContractNew"));
const ContractView = lazy(() => import("../pages/ContractView/ContractView"));
const ContractEdit = lazy(() => import("../pages/ContractEdit/ContractEdit"));
const ContractPrint = lazy(() => import("../pages/ContractPrint/ContractPrint"));
const PawnShop = lazy(() => import("../pages/PawnShop/PawnShop"));
const BuySell = lazy(() => import("../pages/BuySell/BuySell"));
const MonthlyReportPage = lazy(() => import("../pages/Reports/MonthlyReport/MonthlyReport"));
const DailyReportPage = lazy(() => import("../pages/Reports/DailyReport/DailyReport"));
const InvestorPrincipleReportPage = lazy(
  () => import("../pages/Reports/InvestedPrincipalReport/InvestedPrincipalReport")
);

const MoneyTransferList = lazy(
  () => import("../pages/MoneyTransfer/MoneyTransferList/MoneyTransferList")
);
const MoneyTransferNew = lazy(
  () => import("../pages/MoneyTransfer/MoneyTransferNew/MoneyTransferNew")
);
const MoneyTransferView = lazy(
  () => import("../pages/MoneyTransfer/MoneyTransferView/MoneyTransferView")
);
const MoneyTransferEdit = lazy(
  () => import("../pages/MoneyTransfer/MoneyTransferEdit/MoneyTransferEdit")
);

const IncomeExpenseList = lazy(
  () => import("../pages/IncomeExpense/IncomeExpenseList/IncomeExpenseList")
);
const IncomeExpenseNew = lazy(
  () => import("../pages/IncomeExpense/IncomeExpenseNew/IncomeExpenseNew")
);
const IncomeExpenseView = lazy(
  () => import("../pages/IncomeExpense/IncomeExpenseView/IncomeExpenseView")
);
const IncomeExpenseEdit = lazy(
  () => import("../pages/IncomeExpense/IncomeExpenseEdit/IncomeExpenseEdit")
);

const InvestedPrincipalList = lazy(
  () => import("../pages/InvestedPrincipal/InvestedPrincipalList/InvestedPrincipalList")
);
const InvestedPrincipalNew = lazy(
  () => import("../pages/InvestedPrincipal/InvestedPrincipalNew/InvestedPrincipalNew")
);
const InvestedPrincipalView = lazy(
  () => import("../pages/InvestedPrincipal/InvestedPrincipalView/InvestedPrincipalView")
);
const InvestedPrincipalEdit = lazy(
  () => import("../pages/InvestedPrincipal/InvestedPrincipalEdit/InvestedPrincipalEdit")
);
export default function AppRouter() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <DailyReportPage />
    },
    {
      path: "/login",
      element: <Login />
    },
    {
      path: "/contracts/:contractId/view",
      element: <ContractView />
    },
    {
      path: "/contracts/:contractId/edit",
      element: <ContractEdit />
    },
    {
      path: "/contracts/:contractId/print",
      element: <ContractPrint />
    },
    {
      path: "/contracts/new",
      element: <ContractNew />
    },
    {
      path: "/contracts",
      element: <PawnShop />
    },
    {
      path: "/shop-transactions",
      element: <BuySell />
    },
    {
      path: "/money-transfers/:moneyTransferId/view",
      element: <MoneyTransferView />
    },
    {
      path: "/money-transfers/:moneyTransferId/edit",
      element: <MoneyTransferEdit />
    },
    {
      path: "/money-transfers/new",
      element: <MoneyTransferNew />
    },
    {
      path: "/money-transfers",
      element: <MoneyTransferList />
    },
    {
      path: "/income-expenses/:incomeExpenseId/view",
      element: <IncomeExpenseView />
    },
    {
      path: "/income-expenses/:incomeExpenseId/edit",
      element: <IncomeExpenseEdit />
    },
    {
      path: "/income-expenses/new",
      element: <IncomeExpenseNew />
    },
    {
      path: "/income-expenses",
      element: <IncomeExpenseList />
    },
    {
      path: "/invested-principals/:investedPrincipalId/view",
      element: <InvestedPrincipalView />
    },
    {
      path: "/invested-principals/:investedPrincipalId/edit",
      element: <InvestedPrincipalEdit />
    },
    {
      path: "/invested-principals/new",
      element: <InvestedPrincipalNew />
    },
    {
      path: "/invested-principals",
      element: <InvestedPrincipalList />
    },
    {
      path: "/reports/monthly",
      element: <MonthlyReportPage />
    },
    {
      path: "/reports/daily",
      element: <DailyReportPage />
    },
    {
      path: "/reports/investedPrincipal",
      element: <InvestorPrincipleReportPage />
    },
    {
      path: "/reports",
      element: <ReportsPage />
    }
  ]);

  return <RouterProvider router={router} fallbackElement={<PageLoader />} />;
}
